import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from './ChatApp'; // Import ChatApp component
import Profile from './PDFViewer'; // Import PDFViewer component
import Dictionary from "./Dictionary"; // import dictionary component
import { FaSignOutAlt } from "react-icons/fa"; // Import logout icon
import './PDFViewer.css';
const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        navigate('/');
        return;
      }

      try {
        const response = await axios.get('https://8mflejtvk7.execute-api.eu-north-1.amazonaws.com/prod/dashboard', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserData(response.data);
      } catch (err) {
        localStorage.removeItem('token');
        navigate('/');
      }
    };

    fetchData();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await axios.post('https://8mflejtvk7.execute-api.eu-north-1.amazonaws.com/prod/logout', { mobileNumber: userData.mobileNumber });
      localStorage.removeItem('token');
      navigate('/');
    } catch (err) {
      console.error('Logout failed', err);
    }
  };

  return (
    
    <div className="dashboard-container">
    <h2 className="dashboard-title">Dashboard</h2>
{/* Logout Button */}
<button className="logout-button" onClick={handleLogout}>
      <FaSignOutAlt /> {/* Logout Icon */}
    </button>


    {/* Scrollable Content */}
    <div className="dashboard-content">
     <center><Dictionary /></center> 
      <Header />
      {userData && <Profile userData={userData} />}
    </div>
    
  </div>
  );
};

export default Dashboard;