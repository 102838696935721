import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Home, Info, Briefcase, Mail, Menu, X } from "lucide-react"; // Import Icons

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const handleNavigation = (path) => {
    navigate(path);  // Navigate to the page
    setOpen(false);   // Close sidebar
  };

  return (
    <div className="md:hidden">
      <button onClick={() => setOpen(!open)} className="text-white p-2">
        {open ? <X size={24} /> : <Menu size={24} />}
      </button>
      
      {open && (
        <div className="absolute top-16 left-0 w-48 bg-blue-600 text-white p-4 rounded-lg shadow-lg">
          <button className="flex items-center space-x-2 py-2 w-full text-left hover:bg-blue-700 px-3 rounded-md transition" 
            onClick={() => handleNavigation("/")}>
            <Home size={20} /> 
          </button>
          <button className="flex items-center space-x-2 py-2 w-full text-left hover:bg-blue-700 px-3 rounded-md transition" 
            onClick={() => handleNavigation("/about")}>
            <Info size={20} /> 
          </button>
          <button className="flex items-center space-x-2 py-2 w-full text-left hover:bg-blue-700 px-3 rounded-md transition" 
            onClick={() => handleNavigation("/services")}>
            <Briefcase size={20} /> 
          </button>
          <button className="flex items-center space-x-2 py-2 w-full text-left hover:bg-blue-700 px-3 rounded-md transition" 
            onClick={() => handleNavigation("/contact")}>
            <Mail size={20} /> 
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
