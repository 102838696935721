const Services = () => {
    return (
      <div className="p-8">
        <h1 className="text-3xl font-bold">Services</h1>
        <p className="mt-4 text-gray-600">
        Enhance your learning experience with our interactive Read Aloud feature. Access a wide range of school books, competitive exam materials, stories, and novels—all with seamless navigation and text-to-speech support for an immersive reading journey.
        </p>
      </div>
    );
  };
  
  export default Services;
  





