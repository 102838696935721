import React, { useState } from "react";
import { speak, stopSpeaking, toggleMute } from "../utils/tts";
import questionsData from "../data/questions.json";
import stringSimilarity from "string-similarity";
import { FaPaperPlane, FaStopCircle, FaVolumeMute, FaVolumeUp } from "react-icons/fa"; 

import "./ChatApp.css";

function ChatApp() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isMuted, setIsMuted] = useState(false); // Track mute state

  const generateResponse = (input) => {
    if (!input || typeof input !== "string") return "Invalid input. Please enter a question.";
  
    input = input.trim().toLowerCase();
    let bestMatch = null;
    let highestScore = 0;
    let relatedQuestions = [];

    questionsData.forEach(q => {
      if (!q.keywords || !Array.isArray(q.keywords)) return;
      const matchedKeywords = q.keywords.filter(keyword => input.includes(keyword));

      if (matchedKeywords.length > 0) {
        relatedQuestions.push(q.question);
        const questionKeywords = q.keywords.join(" ");
        const similarity = stringSimilarity.compareTwoStrings(input, questionKeywords);
        if (similarity > highestScore) {
          highestScore = similarity;
          bestMatch = q;
        }
      }
    });

    if (highestScore > 0.4) {
      return `**Matched Question:** ${bestMatch.question}\n**Answer:** ${bestMatch.answers.join(" ")}`;
    }

    if (relatedQuestions.length > 0) {
      return `I couldn't find an exact answer, but here are some related questions and you can refer the keyword from Smart reader:\n- ${relatedQuestions.join("\n- ")}`;
    }

    //const allKeywords = questionsData.flatMap(q => q.keywords);
    //return `I'm not sure I understand. Try using these keywords:\n- ${[...new Set(allKeywords)].join(", ")}`;
    //return `I'm not sure I understand. Try using these keywords:\n- ${[...new Set(allKeywords)].join(", ")}`;
  };

  const handleSend = () => {
    if (!input.trim()) return;

    const userMessage = { sender: "user", text: input };
    const botMessage = { sender: "bot", text: generateResponse(input) };

    setMessages((prevMessages) => [...prevMessages, userMessage, botMessage]);
    setInput("");

    // Read bot message aloud
    speak(botMessage.text, 0.8);
  };

  const handleMuteToggle = () => {
    const muted = toggleMute();
    setIsMuted(muted);
  };

  return (
    <div className="flex h-screen">
  

  {/* Main Chat Container */}
  <div className="flex flex-col flex-1">
    {/* Header */}
    <div className="chat-header bg-blue-600 text-white p-4 text-center">
      <h2>Sennai</h2>
    </div>

    {/* Chat Messages */}
    <div className="flex-1 p-4 overflow-auto">
      {messages.map((msg, index) => (
        <div key={index} className={`chat-message ${msg.sender === "user" ? "user-message" : "bot-message"}`}>
          <div className="message-text">{msg.text}</div>
        </div>
      ))}
    </div>

    {/* Chat Input */}
    <div className="chat-input-container flex items-center p-4 border-t">
      <input
        type="text"
        className="chat-input flex-1 p-2 border rounded-lg"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type your social and science question or keyword..."
      />
      <button className="send-button ml-2 bg-blue-600 text-white px-4 py-2 rounded-lg" onClick={handleSend}>
        <FaPaperPlane />
      </button>
      <button className="mute-button ml-2 bg-gray-400 px-4 py-2 rounded-lg" onClick={handleMuteToggle}>
        {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
      </button>
      <button className="stop-button ml-2 bg-red-500 text-white px-4 py-2 rounded-lg" onClick={stopSpeaking}>
        <FaStopCircle />
      </button>
    </div>
  </div>
</div>

  );
}

export default ChatApp;
