const Contact = () => {
    return (
      <div className="p-8">
        <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
        <table className="w-full border-collapse border border-gray-300">
          <tbody>
            <tr className="border-b">
              <td className="p-3 font-semibold">📞 Mobile Number:</td>
              <td className="p-3">9445548285</td>
            </tr>
            <tr className="border-b">
              <td className="p-3 font-semibold">💬 WhatsApp Number:</td>
              <td className="p-3">9445548285</td>
            </tr>
            <tr>
              <td className="p-3 font-semibold">📧 Email:</td>
              <td className="p-3">kumar@sennai.in</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  
  export default Contact;
  