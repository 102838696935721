import React, { useState, useEffect } from "react";
import axios from "axios";

const Dictionary = () => {
  const [word, setWord] = useState("");
  const [definition, setDefinition] = useState(null);
  const [error, setError] = useState(null);
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem("darkMode") === "true";
  });

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
    document.documentElement.classList.toggle("dark", darkMode);
  }, [darkMode]);

  const fetchDefinition = async () => {
    try {
      setError(null);
      const response = await axios.get(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`);
      setDefinition(response.data[0]);
    } catch (err) {
      setDefinition(null);
      setError("Word not found. Try another.");
    }
  };

  // Read Aloud Function for Meaning
  const handleReadAloud = () => {
    if (definition) {
      const meaning = definition.meanings[0].definitions[0].definition;
      const utterance = new SpeechSynthesisUtterance(meaning);
      speechSynthesis.speak(utterance);
    }
  };

  return (
    <div className={`flex flex-col items-center p-6 min-h-screen ${darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-black"}`}>
      

      <h2 className="text-2xl font-bold mb-4">📖 Dictionary</h2>

      <div className="flex space-x-2">
        <input
          type="text"
          value={word}
          onChange={(e) => setWord(e.target.value)}
          placeholder="Enter a word"
          className="border p-2 rounded-lg"
        />
        <button
          onClick={fetchDefinition}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Search
        </button>
      </div>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {definition && (
        <div className={`mt-6 p-4 shadow-lg rounded-lg w-80 text-center ${darkMode ? "bg-gray-800 text-white" : "bg-white text-black"}`}>
          <h3 className="text-xl font-semibold">{definition.word}</h3>
          <p className="text-gray-500">{definition.phonetics[0]?.text}</p>
          
          <p className="mt-3"><strong>Definition:</strong> {definition.meanings[0].definitions[0].definition}</p>

          {/* Read Aloud Button */}
          <button
            onClick={handleReadAloud}
            className="bg-green-500 text-white px-3 py-1 mt-3 rounded-lg hover:bg-green-600"
          >
            🔊 Read Aloud
          </button>
        </div>
      )}
    </div>
  );
};

export default Dictionary;
