import React from "react";
//import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HashRouter, Routes, Route } from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import Dashboard from "./pages/Dashboard";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";


function App() {
  return (
    <HashRouter> 
      <div className="flex">
         {/* Sidebar (Navigation) */}
         <Sidebar />
          {/* Main Content Area */}
        <div className="flex-1 container mx-auto p-4">
      <Routes>
        <Route path="/" element={<AuthPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
         <Route path="/contact" element={<Contact />} />
      </Routes>
      </div>
      </div>
      {/* Footer (Outside of Flex Container) */}
      <Footer />
    </HashRouter>
  );
}

export default App;
