const About = () => {
    return (
      <div className="p-8">
        <h1 className="text-3xl font-bold">About Us</h1>
        <p className="mt-4 text-gray-600">
          We are a company dedicated to providing the best services to our customers.
        </p>
      </div>
    );
  };
  
  export default About;
  