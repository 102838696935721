import React, { useState, useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import './PDFViewer.css';

const PDFViewer = () => {
  const [pdf, setPdf] = useState(null);
  const [pdfText, setPdfText] = useState("");
  const [isReading, setIsReading] = useState(false);
  const [readingSpeed, setReadingSpeed] = useState(.6); // Default reading speed
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [selectedPdf, setSelectedPdf] = useState('/English.pdf');
  const canvasRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false)
  const [pageInput, setPageInput] = useState(""); // For "Go to Page" input

  useEffect(() => {
    loadPdf(selectedPdf);
  }, [selectedPdf]);

  const loadPdf = async (url) => {
    try {
      const loadingTask = pdfjsLib.getDocument(url);
      const loadedPdf = await loadingTask.promise;
      setNumPages(loadedPdf.numPages);
      setPdf(loadedPdf);
      loadPageText(loadedPdf, 1);
    } catch (error) {
      console.error("Error loading PDF:", error);
    }
  };

  const loadPageText = async (pdfDocument, pageNumber) => {
    if (pageNumber < 1 || pageNumber > pdfDocument.numPages) return;
    try {
      const page = await pdfDocument.getPage(pageNumber);
      let textContent = "";

      const text = await page.getTextContent();
      text.items.forEach(item => textContent += item.str + " ");
      setPdfText(textContent);

      renderPage(page);
    } catch (error) {
      console.error("Error loading page:", error);
    }
  };

  const renderPage = async (page) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
  
    const scale = 1.5;
    const viewport = page.getViewport({ scale });
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    context.clearRect(0, 0, canvas.width, canvas.height);
  
    // Cancel previous rendering if any
    if (canvas.renderTask) {
      canvas.renderTask.cancel();
    }
  
    // Create a new render task
    const renderTask = page.render({ canvasContext: context, viewport });
  
    // Store the current render task to track ongoing rendering
    canvas.renderTask = renderTask;
  
    // Wait for the rendering to complete
    try {
      await renderTask.promise;
    } catch (error) {
      if (error.name === "RenderingCancelledException") {
        console.log("Rendering cancelled");
      } else {
        console.error("Error rendering page:", error);
      }
    }
  };
  

  const handleNavigation = (direction) => {
    let newPage = currentPage + direction;
    if (newPage >= 1 && newPage <= numPages) {
      setCurrentPage(newPage);
      loadPageText(pdf, newPage);
    }
  };

  const startReading = () => {
    
    if (isReading || !pdfText) return;
    
    const synth = window.speechSynthesis;
    const speech = new SpeechSynthesisUtterance(pdfText);
    speech.lang = "en-US"; // Set language
    speech.rate = readingSpeed; // Set reading speed
    setIsReading(true);
    

    speech.onend = () => {
      setIsReading(false);
      setIsPaused(false);
    };

    //window.speechSynthesis.speak(speech);
    synth.speak(speech);
    setIsReading(true);
    setIsPaused(false);
  };

  const pauseReading = () => {
    if (isReading) {
      window.speechSynthesis.pause();
      setIsPaused(true);
    }
  };
  const resumeReading = () => {
    if (isPaused) {
      window.speechSynthesis.resume();
      setIsPaused(false);
    }
  };

  const handleGoToPage = async () => {
    const pageNum = parseInt(pageInput);
    if (pageNum >= 1 && pageNum <= numPages) {
      setCurrentPage(pageNum);
      try {
        const page = await pdf.getPage(pageNum); // Get the page object
        await renderPage(page); // Pass the page object to renderPage
        await loadPageText(pdf, pageNum); // Update the text for reading aloud
      } catch (error) {
        console.error("Error loading page:", error);
      }
    } else {
      alert(`Please enter a valid page number between 1 and ${numPages}`);
    }
  };

  const stopReading = () => {
    window.speechSynthesis.cancel();
    setIsReading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-100">
  {/* PDF Selector */}
  <div className="flex items-center space-x-4 bg-white p-3 shadow-md rounded-lg">
    <label className="font-semibold">Select PDF:</label>
    <select 
      onChange={(e) => setSelectedPdf(e.target.value)} 
      value={selectedPdf} 
      className="border p-2 rounded-md"
    >
      <option value="/English.pdf">English</option>
      <option value="/sciencepaper.pdf">Science</option>
      <option value="/socialscience.pdf">Social</option>
      <option value="/maths_paper.pdf">Maths</option>
    </select>
  </div>

  {/* Go to Page Input */}
  <div className="flex items-center space-x-2 mt-4">
    <input
      type="number"
      placeholder="Go to page"
      value={pageInput}
      onChange={(e) => setPageInput(e.target.value)}
      className="border p-2 rounded-md"
    />
    <button onClick={handleGoToPage} className="bg-blue-500 text-white px-4 py-2 rounded-md">
      Go
    </button>
    <p className="text-gray-700">Page {currentPage} of {numPages}</p>
  </div>

  {/* PDF Viewer */}
  <div className="flex justify-center items-center bg-white p-4 shadow-md rounded-lg mt-4">
    <canvas ref={canvasRef} className="w-full max-w-2xl" />
  </div>

  {/* Navigation Controls */}
  <div className="flex items-center space-x-4 mt-4">
    <button 
      onClick={() => handleNavigation(-1)} 
      disabled={currentPage <= 1} 
      className="bg-gray-500 text-white px-3 py-2 rounded-md disabled:opacity-50"
    >
      ◀️
    </button>
    <span>Page {currentPage} of {numPages}</span>
    <button 
      onClick={() => handleNavigation(1)} 
      disabled={currentPage >= numPages} 
      className="bg-gray-500 text-white px-3 py-2 rounded-md disabled:opacity-50"
    >
      ▶️
    </button>
  </div> 

  {/* Read Aloud Controls */}
  <div className="flex flex-col items-center space-y-3 bg-white p-4 shadow-md rounded-lg mt-4">
    <div className="flex space-x-4">
      <button onClick={startReading} disabled={isReading} className="bg-green-500 text-white px-4 py-2 rounded-md disabled:opacity-50">🔊</button>
      <button onClick={pauseReading} onTouchStart={pauseReading} disabled={!isReading || isPaused} className="bg-yellow-500 text-white px-4 py-2 rounded-md disabled:opacity-50">⏸️</button>
      <button onClick={resumeReading} disabled={!isPaused} className="bg-green-500 text-white px-4 py-2 rounded-md disabled:opacity-50">🔊</button>
      <button onClick={stopReading} disabled={!isReading} className="bg-red-500 text-white px-4 py-2 rounded-md disabled:opacity-50">⏹️</button>
    </div>
    <label className="font-semibold">Speed:
      <input 
        type="range" min="0.5" max="2" step="0.1" 
        value={readingSpeed} 
        onChange={(e) => setReadingSpeed(parseFloat(e.target.value))} 
        className="ml-2"
      />
      {readingSpeed}x
    </label>
  </div>
</div>

  );
};

export default PDFViewer;
