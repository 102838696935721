let synth = window.speechSynthesis;
let isMuted = false;

export const speak = (text, rate = 1) => {
  if (isMuted) return; // Prevent speaking if muted

  let utterance = new SpeechSynthesisUtterance(text);
  utterance.rate = rate;
  
  synth.speak(utterance);
};

// Stop the current speech
export const stopSpeaking = () => {
  if (synth.speaking) {
    synth.cancel();
  }
};

// Toggle Mute
export const toggleMute = () => {
  isMuted = !isMuted;
  return isMuted;
};
