import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AuthPage.css";

const AuthPage = () => {
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validateMobile = (value) => {
    const mobileRegex = /^[6-9]\d{9}$/;
    if (!mobileRegex.test(value)) {
      setError("Invalid Whatsapp number.");
      return false;
    } 
    setError("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateMobile(mobile)) return;

    try {
      const response = await axios.post("https://8mflejtvk7.execute-api.eu-north-1.amazonaws.com/prod/auth", { mobile });
      localStorage.setItem("token", response.data.token);
      navigate("/dashboard");
    } catch (err) {
      setError(err.response?.data?.message || "Something went wrong.");
    }
  };

  return (


    
    <div className="auth-container">
      <div className="auth-card">
        <h2>Sennai</h2>
        <h2>Login / Register</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
                        <label htmlFor="mobile">Whatsapp Number</label>
            <input
              type="text"
              id="mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter your Whatsapp number"
            />
            {error && <p className="error-message">{error}</p>}
          </div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AuthPage;
